@charset "utf-8";

/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td
{
	margin: 0;
	padding: 0;
}
address,
em
{
	font-style: normal;
}
strong,
th
{
	font-weight: normal;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
th
{
	text-align: left;
}
hr,
legend
{
	display: none;
}
h1,
h2,
h3,
h4,
h5,
h6
{
	font-size: 100%;
	font-weight: normal;
}
img,
fieldset
{
	border: 0;
}
img
{
	border:0;
	vertical-align:top;
	max-width:100%;
	height:auto;
}

li
{
	list-style-type: none;
}
input[type="submit"],button,label,select{
	cursor:pointer;
}

body {
	overflow-x: hidden;
}

.clearfix{
	@include clearfix;
}
button {
	border:none;
	background: none;
	padding:0;
	outline:0;
	&:active, &:focus {
		outline:none;
	}
}
/* link
------------------------------------------------------------*/
a { outline: none; color: $baseLinkColor; text-decoration: underline;
	&:hover { text-decoration: underline; color: #808080;
}
}

/* Fonts
------------------------------------------------------------*/
html{ font-size: 62.5%; }/* 10px base */

body{
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	overflow-wrap : break-word;
	font-family: $baseFontFamily; 
}
body>div,input,button,textarea,select
{
	@include fz;
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	color: $baseColor;
}

/* text adjust
------------------------------------------------------------*/
.textCenter {
	text-align: center;
}
.textLeft {
	text-align: left;
}
.textRight {
	text-align: right;
}

/* form
------------------------------------------------------------*/
.input100per,textarea { padding: 3px; border: 1px solid #CCC; @include box-sizing; }
