@charset "utf-8";
/* CSS Document */

.container {
	width:auto;
	max-width:$baseWidth;
	
	@include widhtWide{
		max-width:$baseWidthWide;
	
	}
	@include tabletL{
		max-width:$bpTabletP;
	
	}
	@include tabletP{
		width:auto;
		padding-right: 10px;
		padding-left: 10px;
	
	}
}