
@charset "utf-8";
/* layout */
.box-center {
	@include margin-center;
	display: block;
}
#brandList {
	@include clearfix;
	li {
		width: 23%;
		margin:2% 2.1% 0 0;
		float:left;
		@include box-sizing;
		&:nth-child(4n) {
			margin-right:0;
		} 
		img {
			max-width: 100%;
			border:1px solid #ccc;
		}
	}
}
#contentWrap {
	position: relative;
}
// Header
#header {
	position: relative;
	@include clearfix;
	.siteTitle {
		padding: 10px;
		float:left;
	}
	.jpxLogo {
		margin-top:10px;
	}
	#headerMenu {
		position: absolute;
		top:0;
		right:10px;
		width: 90px;
		height: 0;
		padding-top:35px;
		background: url(/sp/assets/img/common/btn_menu.png) no-repeat 0 0;
		display: block;
		overflow: hidden;
		-webkit-background-size: cover; 
		-o-background-size: cover; 
		background-size: cover; 
	}
	#gNavi {
		width: 165px; 
		display: none;
		position: absolute;
		right:-165px;
		top:0;
		background-color: #000;
		z-index:300;
		ul {
			border-bottom:1px solid darken(#fff, 40%);
		}
		li {
			a {
				background: url(/sp/assets/img/common/ico_menuArrow.png) no-repeat 10px 50%;
				padding-left:25px; 
				-webkit-background-size: 7px 6px; 
				-o-background-size: 7px 6px; 
				background-size: 7px 6px;
				display: block;
			}
			img {
				vertical-align: middle;
				display: block;
			}
		}
		.gNaviMain {
				a {
					padding-top:15px;
					padding-bottom:15px;
					border-bottom:1px solid darken(#fff, 40%);
				}
			}
		.gNaviSub {
			a {
				padding-top: 10px;
				padding-bottom:10px; 
			}
		}
		#headerClose {
			display: block;
			width: 100%;
			height: 0;
			padding-top:48px;
			overflow: hidden; 
			border-bottom:1px solid darken(#fff, 40%);
			background: url(/sp/assets/img/common/menu_close.png) no-repeat 50% 50%;
			-webkit-background-size: 42px 9px; 
			-o-background-size: 42px 9px; 
			background-size: 42px 9px; 
		}
	}
}

// Footer
#footer {
	background-color: #000;
	color: #FFF;
	margin-top:30px;
	padding-bottom:20px;
	font-size:90%;
	&Navi {
		margin-bottom:20px;
		li {
			border-bottom:1px solid darken(#fff, 40%);
			a {
				color: #FFF;
				padding:15px 0 15px 20px;
				display: block;
				text-decoration: none;
				background: url(/sp/assets/img/common/ico_footerArrow.png) no-repeat 5px 50%;
				-webkit-background-size: 7px 6px; 
				  -o-background-size: 7px 6px; 
				  background-size: 7px 6px;   
			}
		}
	}  
	.copyright {
		text-align: right;
		display: block;
		margin-right:10px;
		font-size:70%;
	}
}

// Home
#mainVisual {
	margin-bottom:20px;
	width: 100%;
	height: auto; 
	overflow: hidden; 
}

#home h2, #lower h3 {
	border-left:4px solid #000;
	padding-left:10px;
	margin-bottom:10px;
}

// Lower
#lower .bgGrayBox {
	background-color: #F7F7F7;
	padding:10px;
	margin:10px 0 30px; 
	img {
		margin:10px 0 0;
		display: block;
		padding:5px;
	}
	h3 {
		border-left:0;
		padding:8px 0 0 0;
		border-top: 1px solid #000;
		font-weight:bold;
		margin-top:8px;
	}
}
// Shop List
#lower .shopList {
	a {
		text-decoration: none;
	}
	li {
		width: 48%;
		float:left;
		margin-bottom:4%;
		&:nth-child(2n) {
			float:right;
		}
		img {
			@include margin-center;
			display: block;
		}
	}
	h3 {
		font-weight:bold;
		border-bottom: 1px solid #000;
		padding-bottom:5px;
		margin-bottom:5px;
		border-left:0;
		padding-left:0;
	}
	p {
		font-size:90%;
		color: #666; 
	}
}

#lower .shopDetail {
	.arrowList {
		margin-top:-10px;
		font-size:90%;
	}
}

// Privacy Policy
.privacypolicy {
	h3 {
		padding:2px 0 2px 10px;
		font-size:110%;
		line-height:1;
		font-weight:bold;
		margin-bottom:20px;
	}
	&__line {
		padding-bottom:20px;
		margin-bottom:20px;
		border-bottom:1px dotted #ccc;
	}
	h4 {
		font-weight:bold;
		span {
			font-size:150%;
		}
	}
	address {
		em {
			font-weight:bold;
		}
	}
}
#lower .mind .bgGrayBox {
	margin-top:0;
	h4 {
		font-weight:bold;
	}
}
