@charset "utf-8";

/* btn
------------------------------------------------------------*/
.btnLightArrow {
	background: url('/assets/img/common/ico_blackArrow.png') no-repeat 12px 50% #FFF;
	border:1px solid #ccc; 
	padding:5px 20px 5px 35px;
	@include border-radius(4px);
	text-decoration: none;
	font-size:90%;
	&:hover {
		text-decoration: none;
	}
}

/* title
------------------------------------------------------------*/
.leftLineTitle {
	border-left:4px solid #000;
	padding-left:20px;
	margin-bottom:20px;
}

/* table
------------------------------------------------------------*/
.dottedLineTable {
	th, td {
		padding:25px 10px;
		background: url('/sp/assets/img/lower/common/line_dotted.jpg') repeat-x 0 100%;
	}
	th {
		font-weight:bold;
	}
}


/* link
------------------------------------------------------------*/
.permeable:hover img {
	@include alpha-image(0.5);
}
.breadcrumb {
	font-size:90%;
	margin-top:20px;
	margin-bottom:20px;
	> * {
		display: inline-block;
		line-height:1;
	}
	a {
		background: url(/assets/img/lower/common/ico_stepArrow.png) no-repeat 100% 100%;
		padding-right:20px;
		margin-right:10px;
	}
}

/* list
------------------------------------------------------------*/
.listInline {
	li {
		display: inline;
	}
}
.arrowList {
	@include clearfix;
	border-bottom:1px dotted #ccc;
	li {
		width: 50%;
		float: left;
		background-image: url('/sp/assets/img/lower/common/ico_blackArrow.png');
		background-repeat: no-repeat;
		background-position: 3px 25px;
		-webkit-background-size: 8px 7px; 
		  -o-background-size: 8px 7px; 
		  background-size: 8px 7px;   
		padding:20px 0 20px 20px;
		border-top:1px dotted #ccc;
		@include box-sizing;
		&:nth-child(2n+1) {
			clear:both;
		}
		}
	a {
		text-decoration: none;
		display: block;
	}
	.bottomBorder {
		border-bottom:1px dotted #ccc;
	}
	.noBorder, li:nth-child(1), li:nth-child(2) {
		border:none;
	}
}
.lineList {
	li {
		padding:25px 0;
		background: url('/sp/assets/img/common/line_dotted.jpg') repeat-x 0 100%;
		span {
			font-weight:bold;
			margin-right:30px;
		}
	}
}


/* block
------------------------------------------------------------*/
.block {
	display: block;
}
.boxCenter {
	@include margin-center;
	display: block;
}

.basisBoxlink {
	border:1px solid #666;
	padding:5px;
	display: block;
	&:hover {
			filter:alpha(opacity=50);
			opacity:0.5;
			text-decoration: none;
	}
}
.bottomArrow {
	position: relative;
	display: block;
	&:after {
		content:'';
		right:0;
		bottom:0;
		width: 25px;
		height: 25px;  
		position: absolute;
		display: block;
		background: url('/sp/assets/img/common/ico_triangleArrow.png') no-repeat 0 0;
		-webkit-background-size: cover; 
		 -o-background-size: cover; 
		 background-size: cover;  
	}
}

