/* Slider */

.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    width: 95%;
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: none;
    height: 40px;
    width: 40px;
    cursor: pointer;
    color: transparent;
    margin-top: -10px\9; /*lte IE 8*/
    padding: 0;
    border: none;
    outline: none;
    top:50%;
    z-index:200;
    &:hover, &:focus {
        outline: none;
        color: transparent;
    }
}


/* Dots */
.slick-dots {
    position: relative;
    bottom: 0;
    right:0;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    z-index:100;
    li {
        position: relative;
        display: inline-block;
        height: 10px;
        width: 10px;
        margin: 0 8px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background-color:#999;
            display: block;
            height: 10px;
            width: 10px;
            -webkit-border-radius: 50%; 
            border-radius: 50%; 
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                background-color: #000; 
            }
        }
        &.slick-active button {
            background-color: #000;
        }
    }
}

